import React from 'react';
import { Blog, WebsiteWrapper } from '../../parts';
import PageWrapper from '../../containers/PageWrapper';

const BlogPage = ({ location, pageContext }) => {
  const { lang, translations } = pageContext;
  return (
    <PageWrapper lang={lang} translations={translations}>
      <WebsiteWrapper isNavBgWhite={true} location={location}>
        <Blog pageContext={pageContext} />
      </WebsiteWrapper>
    </PageWrapper>
  );
};

export default BlogPage;
